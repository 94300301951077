import { IconProps } from '@appTypes';
import * as React from 'react';

function MailIconPartners(props: IconProps) {
  const { fill = '#243636' } = props;
  return (
    <svg viewBox='0 0 24 24' width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 6c0-1.65685 1.34315-3 3-3h16c1.6569 0 3 1.34315 3 3v12c0 1.6569-1.3431 3-3 3H4c-1.65685 0-3-1.3431-3-3V6Zm3-1c-.55228 0-1 .44772-1 1v1.43381l8.4855 5.09129c.3167.19.7123.19 1.029 0L21 7.43381V6c0-.55228-.4477-1-1-1H4Zm17 4.76619-7.4565 4.47391c-.9501.57-2.1369.57-3.087 0L3 9.76619V18c0 .5523.44772 1 1 1h16c.5523 0 1-.4477 1-1V9.76619Z'
        fill={fill}
      />
    </svg>
  );
}

export default MailIconPartners;
