import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { PageContext, IPrismicPartners } from '@appTypes';
import FooterSection from '@pageSections/common/footer';

import CallToActionSection from '@pageSections/common/callToAction';
import HeaderSection from '@pageSections/partnersPage/header';
import PartnersList from '@pageSections/partnersPage/partnersList';

const PartnersPage: React.FC<PageProps<IPrismicPartners, PageContext>> = ({
  data: { prismicFooterData, prismicData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url } = data;

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        image={meta_image.url}
        url={meta_url}
        pathname={location.pathname}
        name='Hyperhuman'
        locale={locale}
      />
      <HeaderSection {...data} />
      <PartnersList {...data} />
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default PartnersPage;

export const pageQuery = graphql`
  query PartnersQuery($locale: String!) {
    prismicData: allPrismicPartners(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            meta_url
            meta_keywords
            header_title {
              text
            }
            header_subtitle {
              text
            }
            partners {
              partner_description {
                html
              }
              partner_email
              partner_locations
              partner_logo {
                url
                alt
              }
              partner_name
              partner_video {
                url
              }
              partner_website
              show_in_homepage
              partner_business_type
            }
            title
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
