import React from 'react';
import styled from '@emotion/styled';
import { HeaderTitle, HeaderSubtitle, theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import { PartnersHeaderSectionData } from './types';

const { breakpoints } = theme;

const HeaderSection: React.FC<PartnersHeaderSectionData> = (props) => {
  const { header_title, header_subtitle } = props;

  return (
    <SectionWrapper id='featuresHeader'>
      <TitleWrapper>
        <HeaderTitle>{header_title.text}</HeaderTitle>
        <HeaderSubtitleWrapper>
          <HeaderSubtitle>{header_subtitle.text}</HeaderSubtitle>
        </HeaderSubtitleWrapper>
      </TitleWrapper>
    </SectionWrapper>
  );
};

export default HeaderSection;

const TitleWrapper = styled.div`
  margin: 0 auto;
  h1 {
    max-width: 290px;
  }
  h2 {
    max-width: 300px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    h1 {
      max-width: 100%;
    }
    h2 {
      max-width: 100%;
    }
  }
`;

const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  margin-bottom: 60px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;

const HeaderSubtitleWrapper = styled.div`
  margin: 0 auto;
`;
