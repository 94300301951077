import { IconProps } from '@appTypes';
import * as React from 'react';

function MapPinPartners(props: IconProps) {
  const { fill = '#243636' } = props;
  return (
    <svg fill='none' height='24' width='24' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='m11.9167 1.75c-4.50008 0-8.1667 3.75739-8.1667 8.4167 0 3.1429 1.78334 6.1429 3.51291 8.2633.8819 1.0813 1.79256 1.9863 2.54574 2.6289.37525.3202.72525.587 1.02405.7804.1482.096.301.185.4496.2539.1154.0534.3546.1568.6344.1568.2797 0 .519-.1034.6343-.1568.1486-.0689.3014-.1579.4496-.2539.2989-.1934.6489-.4602 1.0241-.7804.7532-.6426 1.6638-1.5476 2.5457-2.6289 1.7296-2.1204 3.5129-5.1204 3.5129-8.2633 0-4.65931-3.6666-8.4167-8.1666-8.4167zm-6.08337 8.4167c0-3.4413 2.71368-6.25003 6.08337-6.25003 3.3696 0 6.0833 2.80873 6.0833 6.25003 0 2.3571-1.3833 4.8571-3.0183 6.8616-.8004.9813-1.6215 1.795-2.2785 2.3555-.3205.2735-.589.4755-.7865.6049-.1976-.1294-.466-.3314-.7865-.6049-.657-.5605-1.47814-1.3742-2.27853-2.3555-1.635-2.0045-3.01834-4.5045-3.01834-6.8616zm6.08337-3.4167c-1.8989 0-3.4167 1.61655-3.4167 3.5833 0 1.9668 1.5178 3.5834 3.4167 3.5834s3.4166-1.6166 3.4166-3.5834c0-1.96675-1.5177-3.5833-3.4166-3.5833zm-1.3334 3.5833c0-.79461.6089-1.41663 1.3334-1.41663.7244 0 1.3333.62202 1.3333 1.41663 0 .7947-.6089 1.4167-1.3333 1.4167-.7245 0-1.3334-.622-1.3334-1.4167z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
}

export default MapPinPartners;
