import * as React from 'react';

import { IconProps } from '@appTypes';

function PlayIcon(props: IconProps) {
  const { fill } = props;
  return (
    <svg width='35' height='35' viewBox='0 0 35 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.333984 8.72266C0.333984 4.90438 0.333984 2.99524 1.13192 1.92942C1.82728 1.0006 2.89169 0.419487 4.04902 0.336826C5.37705 0.241973 6.98298 1.27435 10.1948 3.33912L29.2929 15.6165C32.08 17.4082 33.4735 18.304 33.9549 19.4431C34.3754 20.4384 34.3754 21.5616 33.9549 22.5569C33.4735 23.696 32.08 24.5918 29.2929 26.3835L10.1948 38.6609C6.98298 40.7256 5.37705 41.758 4.04902 41.6632C2.89169 41.5805 1.82728 40.9994 1.13192 40.0706C0.333984 39.0048 0.333984 37.0956 0.333984 33.2773V8.72266Z'
        fill={fill}
      />
    </svg>
  );
}

export default PlayIcon;
