import React from 'react';
import styled from '@emotion/styled';
import { PartnersListSectionData } from './types';
import BusinessPartnerCard from '@components/Cards/BusinessPartnerCard';
import { theme } from '@styles';

const { breakpoints } = theme;

const PartnersList: React.FC<PartnersListSectionData> = (props) => {
  const { partners } = props;
  return (
    <Wrapper>
      <PartnersWrapper>
        {partners.map((partner, index) => {
          return <BusinessPartnerCard partner={partner} />;
        })}
      </PartnersWrapper>
    </Wrapper>
  );
};

export default PartnersList;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto 0px auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0 auto -50px auto;
  }
`;

const PartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    gap: 32px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
