import { IconProps } from '@appTypes';
import * as React from 'react';

function GlobeIconPartners(props: IconProps) {
  const { fill = '#243636' } = props;
  return (
    <svg viewBox='0 0 24 24' width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.84709 4.83889c-2.70473.89076-4.74421 3.3023-5.12411 6.25531h2.99141c.20365-2.22926.93807-4.37142 2.1327-6.25531Zm4.62421 0c1.1946 1.88389 1.929 4.02605 2.1327 6.25531h2.9914c-.3799-2.95301-2.4194-5.36455-5.1241-6.25531Zm.1229 6.25531c-.2353-2.20505-1.0772-4.30636-2.435-6.07608-1.3578 1.76972-2.19972 3.87103-2.43508 6.07608h4.87008Zm-4.87008 2h4.87008c-.2353 2.2051-1.0772 4.3065-2.435 6.0762-1.3578-1.7697-2.19972-3.8711-2.43508-6.0762Zm-2.00974 0h-2.9914c.37989 2.9531 2.41938 5.3646 5.12409 6.2554-1.19462-1.8839-1.92904-4.0261-2.13269-6.2554Zm6.75692 6.2554c1.1946-1.8839 1.929-4.0261 2.1327-6.2554h2.9914c-.3799 2.9531-2.4194 5.3646-5.1241 6.2554Zm-2.3089 2.3696c5.259-.0017 9.4968-4.3241 9.4968-9.625 0-5.30189-4.2396-9.62496-9.5-9.62496-5.26044 0-9.50002 4.32307-9.50002 9.62496 0 5.3009 4.23783 9.6233 9.49672 9.625H12.1624Z'
        fill={fill}
      />
    </svg>
  );
}

export default GlobeIconPartners;
